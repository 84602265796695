import { getCookie } from "../services/session";
import { getHostConfig } from "./config";

export const ErrorCode = {
  SOMETHING_WRONG: 999,
};

export const Endpoint = {
  AUTH: "auth/token",
  USER_LOGIN: "user/admin/generatOtp",
  VERIY_OTP: "user/admin/verifyOtp",
  MEMBER: "member/",
  MEMBERS: "members/",
  MEMBER_LOGOUT: "logoutFromCms",
  GENDER: "gender/",
  DESIGNATION: "designation/",
  INDUSTRY: "industry/",
  MEMBER_TYPE: "mType/",
  MERIT_STATUS: "mStatus/",
  CONTACT_TYPE: "act/",
  INTREST: "mIntrest/",
  INSTITUTE: "institute/",
  STAF_MEMBER: "staff/",
  STAF_MEMBERS: "staffs/",
  EVENT: "event/",
  EVENT_MANAGE: "cms/event-manage",
  EVENT_LIST: "events/list",
  EVENT_CAT: "common/event-category",
  IDEAS_MANAGE: "cms/aioi-manage",
  IDEAS: "idea/",
  IDEAS_LIST: "ideas/",
  QSPECIAL_MANAGE: "cms/qspecial-manage",
  QSPECIAL: "newAtTheQ/",
  HOWTOQ: "howtoq/",
  Qtraveller: "qtraveller/",
  QSPECIAL_LIST: "newAtTheQ/list",
  QSPECIAL_CATEGORIES: "newAtTheQ/list/categories",
  RESERVATION_TYPE: "appConfig/",
  RESERVATION: "reservation/",
  RESERVATION_LIST: "reservation/list",
  ALLSPACES: "allspaces/",
  SPACE: "space/",
  DOWNLOAD_CSV_RESERVATION: "reservation_download",
  LOCATION: "globalAccess/list/continents",
  // GLOBAL_ACCESS: "globalAccess/list",
  GLOBAL_ACCESS_ID: "globalAccess/",
  MEDIA_UPLOAD: "common/media-upload",
  CHECKIN_MEMBER: "checkin/?userType=M",
  CHECKIN_GUEST: "checkin/?userType=G",
  GUEST_MANAGE: "cms/guest-manage",
  MODULE_MANAGE: "cms/module-manage",
  ROLE_MANAGE: "user/admin/roles/",
  CLUB_MANAGE: "cms/club-manage",
  INIVITE_LINK: "member/guest-invite-data/1",
  LOGOUT: "cms/logout",
  MEMBER_CATEGORIES: "cms/membership-categories",
  AUTOSUGGETIONS: "common/auto-suggestion",
  UPLOAD: "https://quorumbucket2.s3.ap-south-1.amazonaws.com/",
  GUESTPOLICY: "club-static-pages/club-guest-policy",
  CLUBRULES: "club-static-pages/club-rules",
  CLUBTIMING: "club-static-pages/club-timing",
  PUSHNOTIFICATION: "utils/pushFilter",
  PUSHLOGS: "logs/cms-push",
  PUSHLOGSSEARCH: "logs/cms-push",
  POPNOTIFICATION: "utils/popFilter",
  POPLOGS: "logs/cms-pop",
  QPAY_GENERATE_TOKEN: "getToken",
  QPAY_SEND_OTP: "generateOTP",
  QPAY_VERIFY_OTP: "verifyOTP",
  QPAY_GET_ALL_ROLES: "roles",
  QPAY_ADMIN: "admin",
  QPAY_ADMINS: "admins",
  QPAY_ADMIN_LOGS: "qPayLogs/admin",
  QPAY_ROLE: "role",
  QPAY_ROLES: "roles",
  QPAY_TERMS: "term",
  QPAY_TERMS_LOGS: "qpaylogs/term",
  QPAY_CMS_TXN_LIST: "cmsTxnLists",
  QPAY_WALLEAT_MEMBERS: "getAllmembers",
  QPAY_MEMBERS_LOGS: "getLogs",
  QPAY_CHANGE_WALLEAT_STATUS: "changeWalletStatus",
  QPAY_DEPOSITE: "depositFromCms",
  QPAY_WITHDRAW: "withdrawFromCms",
  QPAY_GET_INCENTIVE: "incentivesForCms",
  QPAY_INCENTIVE: "incentive",
  QPAY_INCENTIVE_LOGS: "qPayLogs/incentive",
  QPAY_DASHBAORD_STATE: "dashboardStats",
  QPAY_NOTES: "note",
  HOW_TO_Q_LISTS: "qlists",
  HOW_TO_Q_GET: "qlist",
  HOW_TO_Q_ADD: "qhowto",
  HOW_TO_Q_EDIT: "editq",
  HOW_TO_Q_DELETE: "deleteq",
  HOW_TO_T_LISTS: "travellers",
  HOW_TO_T_GET: "traveller",
  HOW_TO_T_ADD: "traveller",
  HOW_TO_T_EDIT: "editTraveller",
  HOW_TO_T_DELETE: "deleteTraveller",
  NEW_AT_CATEGORY: "newAtQCategory",
  NEW_AT_ADD_EDIT: "newAtQ",
  NEW_AT_LIST_BY_CLUB_ID_OR_CATEGORY_ID: "newAtQ",
  NEW_AT_LIST_BY_CLUB_ID: "newAtQ",
  CLUBS: "clubs/",
  CLUB: "club/",
  STAFF_ROLE: "staff/role/",
  STAFF_ROLES: "staff/roles/",
  AUTH_LOGOUT: "auth/logout",
  AUTH_ACCESS_TOKEN: "auth/getToken",
  AUTH_VERIFY_OTP: "auth/verifyOtp",
  AUTH_SEND_OTP: "auth/sendOtp",
  STATIC_TIMINGS: "static/timings",
  STATIC_RULES: "static/rules",
  STATIC_POLICY: "static/guestPolicy",
  STATIC_NOTICE_BOARD: "static/noticeboard",
  STATIC_FEEDBACK: "static/feedback",
  STATIC_FEEDBACK_EMAIL: "static/feedbackEmail",
  INDIAIDEA: "indiaIdea/",
  VIDEOCATEGORY: "videoCategory/",
  GLOBAL_ACCESS: "globalAccess/",
  CONTINENTS: "continents/",
  CONTINENT: "continent/",
  COUNTRIES: "countries/",
  COUNTRY: "country/",
  BOOKNOW: "bookNow/",
  QPERKS: "qperks/",
  CATEGORY: "category/",
  MAIN_IMG: "mainImg/",
  PRIVATE_HIRE_EMAIL: "privateHire/email/",
  PRIVATE_HIRE_EMAILS: "privateHire/emails/",
  PRIVATE_HIRE_SPACE: "privateHire/space/",
  EVENTS: "events/",
  CATEGORIES: "categories/",
  RSVP: "rsvp/",
  RESERV: "reserv/",
  CHECKIN: "checkIn/",
  GUEST: "guest/",
  GUESTINVITATION: "guestInvitation/",
  COMPANY: "company/",
  FILTER_MEMBER: "filterMember/",
  LOGS: "logs/",
  ANALYTICS: "analytics/",
  CODE: "code/",
  APP: "app/",
  CHECKIN: "checkin/",
  STATIC: "static/",
  VIDEO: "video/",
  DINE: "dine/",
  QPAY: "qpay/",
  GLOBAL: "global/",
  PRIVATE: "private/",
  PUSHNOTICODE: "pushNotiCode/",
  PUSHNOTI: "pushNoti/",
  POPNOTI: "popNoti/",
};

export const S3_BUCKET_CRED = {
  BUCKET_ACCESS_KEY:"AKIAXEP4EV7JRGUKVOWS",
  BUCKET_SECRET_KEY:"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",
  BUCKET_NAME: "quorumclubindia",
  BUCKET_REGION: "ap-south-1",
};

export const ROUTER_MAPPER = {
  admin_roles_access: "/admin-access",
  an_india_of_ideas: "/indiaofideas",
  //check_in_a_member: "/",
  edit_member_details: "/member-list/member",
  events: "/events",
  golbal_access: "/global-access",
  invite_a_guest: "/invite-guests",
  //make_a_reseravtion: "/make-reservation",
  q_shop: "/",
  q_specials: "/Qspecial",
  q_whats_app: "/",
  take_away: "/",
  view_checked_in_member: "/checkin",
  guest_managenet: "/guest-check-in",
  view_reservations: "/reservation",
};

export const NOT_FOUND_PAGE = "/407";

export const NOT_ACCESSIBLE_PAGE = "/login";

export const cookieExpiry = 365;
export const cookieName = "quorum-token";

export const MobileMinWidth = 320;
export const MobileMaxWidth = 767;

export const TabletMinWidth = 768;
export const TabletMaxWidth = 1023;

export const DesktopMinWidth = 1024;

export const Messages = {
  API_BROKEN: "Api call is broken, fix the Api service",
  INVALID_FORM_VALUES: "Invalid Form Fields",
  LOGIN_FAILED: "Login Failed",
  DELETE_REPORT_FAILED: "Report did not delete successfully",
  DELETE_REPORT_SUCCESS: "Report deleted successfully!",
  REPORT_SAVE_SUCCESS: "Report saved successfully!",
  REPORT_SAVE_FAILED: "Report did not save successfully!",
};

export function checkIfMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

export function getFileExtension(file) {
  if (!file) return;
  return file.split(".").pop();
}

export const IMAGE_BOX_SIZE = {
  project_list: {
    h: 260,
    w: 463,
  },
  project_media: {
    h: 338,
    w: 600,
  },
  projetc_image: {
    w: 754,
    h: 424,
  },
  common: {
    h: 213,
    w: 160,
  },
  deck_media: {
    h: 338,
    w: 600,
  },
  deck_slider: {
    h: 814,
    w: 1448,
  },
};

export const MEMBERTYPE = {
  1: "Permanent",
  2: "Local",
};

export const ALTERNATE_CONTACTTYPE = {
  1: "Family",
  2: "P. Assistant",
  3: "None",
};

export const MEMBER_TITLE = {
  1: "Mr.",
  2: "Mrs.",
  3: "Ms.",
};

export const MERITAL_STATUS = {
  m1: "Married.",
  p: "Partnered.",
  s: "Single.",
};

export const GENDER = {
  1: "Male",
  2: "Female",
  3: "LGBTQIA",
};

export const CLEARANCE_LEVEL = {
  1: "Family",
  2: "P. Assistant",
  3: "None",
};

export const HOBBIES = [
  "MUSIC",
  "LITERATURE",
  "ENVIRONMENT",
  "CINEMA",
  "TRAVEL",
  "FOOD",
  "THEATRE",
  "SPORTS",
  "FITNESS",
  "WINE/SPRIT",
  "TASTING",
  "TECHNOLOGY",
  "POLITICS",
  "NEW ECONOMY",
  "DESIGN/ARCHITECTURE",
  "ART/EXHIBITION",
];

export const HOST_CONFIG = getHostConfig();
export const API_BASE_PATH = HOST_CONFIG["API_BASE_PATH"];
export const API_BASE_PATH_NEW = HOST_CONFIG["API_BASE_PATH_NEW"];
export const API_Q_PAY_BASE_PATH = HOST_CONFIG["API_Q_PAY_BASE_PATH"];

// analytics

export const analytics_types = {
  section_statistics: "section-statistics",
  app_members: "app-members",
};

// export user json object

export const M_ALTER = {
  1: "Family",
  2: "Personal Assistant",
  3: "None",
};
export const USERMODEL = [
  { label: "MemberStatus", key: "status" },
  { label: "Membership No", key: "mNo" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Prefix", key: "prefix" },
  { label: "First Name", key: "firstName" },
  { label: "Middle Name", key: "middleName" },
  { label: "Last Name", key: "lastName" },
  { label: "Phone Number", key: "phoneNo" },

  { label: "Email Id", key: "email" },
  { label: "Date of Birth", key: "dob" },
  { label: "Address and Pin Code", key: "address" },
  { label: "Company", key: "company" },
  { label: "Industry", key: "industry" },
  { label: "Job Title", key: "jobTitle" },
  { label: "Alternate Contact ", key: "acType" },
  { label: "AC First Name", key: "acFName" },
  { label: "AC Last Name", key: "acLName" },

  { label: "AC Phone Number", key: "acPhoneNo" },
  { label: "AC Email Id", key: "acEmail" },
  { label: "Founder Member Ref 1", key: "founderName1" },
  { label: "Founder Member Ref 2", key: "founderName2" },
  { label: "Favorite Film", key: "fFilm" },
  { label: "Favorite Drink", key: "fDrink" },
  { label: "Favorite Spice ", key: "fspice" },
  { label: "Fitness Activity", key: "fitnessActivity" },
  { label: "Marital Status ", key: "msType" },

  { label: "Spouse First Name", key: "msFName" },
  { label: "Spouse Last Name", key: "msLName" },
  { label: "Spouse Phone Number", key: "msPhoneNo" },
  { label: "Spouse Email Id", key: "msEmail" },
  { label: "Spouse DOB", key: "msDOB" },
  { label: "Spouse Anniversary", key: "msAnniversary" },

  { label: "Travel", key: "travel" },
  { label: "Food", key: "food" },
  { label: "Wine", key: "wine" },

  { label: "Literature", key: "literature" },
  { label: "Art", key: "art" },
  { label: "Design", key: "design" },
  { label: "Theater", key: "theater" },
  { label: "Music", key: "music" },
  { label: "Cinema", key: "cinema" },
  { label: "Spirits Beverages", key: "spirits_beverages" },
  { label: "Sports", key: "sports" },
  { label: "Cocktails", key: "cocktails" },

  { label: "Environment", key: "enviorenment" },
  { label: "Technology", key: "technology" },
  { label: "New Economy", key: "new_economy" },
  { label: "Politics", key: "politics" },
  { label: "Current Affairs", key: "current_affairs" },
  { label: "Wellness", key: "wellness" },
  { label: "Fashion", key: "fashion" },
  { label: "Cooking", key: "cooking" },
  { label: "Yoga", key: "yoga" },
  { label: "Meditation", key: "meditation" },
  { label: "Workshops", key: "workshops" },
  { label: "Skill Development", key: "skill_development" },
  { label: "Entrepreneurship", key: "entrepreneurship" },
  { label: "Reading", key: "reading" },
  { label: "Finance", key: "Finance" },
  { label: "Fitness", key: "Fitness" },
  { label: "Child 1 Name", key: "Child 1 Name" },
  { label: "Child 1 DOB", key: "Child 1 DOB" },

  { label: "Child 2 Name", key: "Child 2 Name" },
  { label: "Child 2 DOB", key: "Child 2 DOB" },
  { label: "Child 3 Name", key: "Child 3 Name" },
  { label: "Child 3 DOB", key: "Child 3 DOB" },
  { label: "Child 4 Name", key: "Child 4 Name" },
  { label: "Child 4 DOB", key: "Child 4 DOB" },

  { label: "Check Ins", key: "check ins" },
  { label: "Guest Invites", key: "guest invites" },
  { label: "Reservations", key: "reservations" },
  { label: "Events", key: "events" },
  { label: "Specials", key: "specials" },
  { label: "Reciprocals", key: "reciprocals" },
  { label: "Q Connect", key: "q connect" },
  { label: "Videos", key: "videos" },
  { label: "Private Hire", key: "private hire" },
  { label: "Edit Profile", key: "edit profile" },
  { label: "All Static Pages", key: "all static pages" },
  { label: "Location", key: "club" },
  { label: "Last Seen", key: "last seen" },
];

export let CLUB_NAME = {};

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const request = new XMLHttpRequest();
const url = `${API_BASE_PATH_NEW}${"clubs/"}`;
request.open("GET", url);
request.setRequestHeader("Authorization", getCookie("quorum-token"));
request.send("");

request.onload = () => {
  const res = request.response;
  const jsonRes = JSON.parse(res);
  jsonRes.clubs.forEach((el) => {
    CLUB_NAME[el._id] = capitalize(el.clubName);
  });
};
